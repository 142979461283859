import React, { useState } from "react";
import * as Accordion from "@radix-ui/react-accordion";

export const FAQja = () => {
  const [activeTab, setActiveTab] = useState("item1");

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
  };

  const getTabStyle = (tabName) => ({
    borderBottom: activeTab === tabName ? "2px solid #52c0e9" : "none",
    color: activeTab === tabName ? "#52c0e9" : "#ACBFC6",
    fontWeight: activeTab === tabName ? "normal" : "350",
  });

  return (
    <div className="tw-w-full tw-mt-16">
      <div className="tw-flex tw-justify-center tw-overflow-scroll tw-overflow-y-hidden md:tw-overflow-hidden tw-gap-4 tw-scrollbar-hidden">
        <button
          style={getTabStyle("item1")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item1")}>
          一般情報
        </button>
        <button
          style={getTabStyle("item2")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item2")}>
          ポイント獲得
        </button>
        <button
          style={getTabStyle("item3")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item3")}>
          会員特典
        </button>
        <button
          style={getTabStyle("item4")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item4")}>
          ポイント交換
        </button>
        <button
          style={getTabStyle("item5")}
          className="tw-pb-2 tw-bg-transparent tw-text-[16px] tw-leading-[20px] tw-font-gotham tw-uppercase"
          onClick={() => handleTabChange("item5")}>
          ポイントで支払い
        </button>
      </div>

      <div className="tw-mt-6">
        {activeTab === "item1" && (
          <div className="tw-flex tw-flex-col tw-gap-3">
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>アヤナリワードの参加ブランドはどこですか？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <ol
                    type="a"
                    className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1 tw-mb-0">
                    <li>
                      参加ホテル、リゾート＆レジデンスには、アヤナ ミッドプラザ
                      ジャカルタ、デロニクスホテル
                      カラワン、デロニクスレジデンス カラワン、アヤナ リゾート
                      バリ、アヤナ セガラ バリ、アヤナ ヴィラズ バリ、リンバ by
                      アヤナバリ、およびアヤナ コモド
                      ワエチチュビーチが含まれます。
                    </li>
                    <li>
                      その他の参加ブランドはリバーサイドゴルフクラブです。
                    </li>
                  </ol>
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item2"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    アヤナリワードにサインアップするにはどうすればよいですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  <span>
                    メンバーは
                    <a
                      href="http://rewards.ayana.com/ja/signup"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="tw-ml-1">
                      https://rewards.ayana.com/signup
                    </a>
                    でオンライン登録できます。
                  </span>
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 3: Membership Card */}
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    現物またはデジタルの会員カードを受け取ることはできますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  現在、アヤナリワードの会員には現物またはデジタルの会員カードが提供されていません。ただし、
                  <a
                    href="https://rewards.ayana.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="tw-ml-1">
                    https://rewards.ayana.com/ja
                  </a>
                  に会員としてログインすることでアカウントにアクセスできます。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 4: Membership Benefits */}
              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    アヤナリワードの会員としてどのような特典を受けることができますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  すべてのアヤナリワードの会員は、会員レベルに応じてアヤナリワードポータルの「メンバー特典」セクションに記載されている特典を受けることができます。ただし、特定の特典は特定の利用規約の対象となる場合がありますのでご了承ください。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 5: Sharing Membership */}
              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    パートナーと私はアヤナリワードの会員資格を共有できますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  アヤナリワードの会員資格は個人単位で授与されます。パートナー用の共同アカウントや1人用の複数アカウントは許可されていません。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 6: Point Transfers */}
              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    私のポイントを別の人に移行したいのですが、それは可能ですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  現在、ポイントを他の人に譲渡することは許可されていません。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 7: Points Expiration */}
              <Accordion.Item
                value="item7"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>ポイントの有効期限はありますか？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  会員が最後のアクティブから24ヶ月以内にアクティブステータスを維持できない場合、アカウントは無効化され、獲得したすべてのポイントは無効になります。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 8: Maintaining Active Status */}
              <Accordion.Item
                value="item8"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    アクティブステータスを維持するにはどうすればいいですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  会員は、ウェブサイトのログイン活動、資格のある滞在の完了、資格のある支出の完了、またはAYANAリワードポイントの利用によってアクティブステータスを維持できます。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 9: Contact Information */}
              <Accordion.Item
                value="item9"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>Dアヤナリワードには電話窓口がありますか？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  現在、アヤナリワードはinfo@ayanarewards.comでのみお問合せ可能です。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 10: Gold or Platinum Status */}
              <Accordion.Item
                value="item10"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    ゴールドまたはプラチナステータスを維持するにはどうすればいいですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  会員は、最後のアップグレード、ダウングレード、または維持から翌年の12月31日までに累積されたレベルポイントまたは有資格宿泊数を保持する必要があります。
                  最低ポイントや宿泊数の要件を満たせなかった場合、会員のレベルがダウングレードされます。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 11: Upcoming Bookings */}
              <Accordion.Item
                value="item11"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    予約を完了しましたが、どうして今後の予約セクションに表示されないのですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  予約および予約の変更が「今後の予約」セクションに表示されるまで最大72時間かかる場合があります。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 12: Member Rates */}
              <Accordion.Item
                value="item12"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    アヤナリワードにサインアップしたばかり、またはメンバーとしてサインインするのを忘れました。既存の予約に会員料金を適用できますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  会員は、部屋を予約した後にサインアップした場合、または参加ホテルで部屋を予約するときにログインしなかった場合、会員限定の部屋割引を請求する資格はありません。
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
        {activeTab === "item5" && (
          <div>
            <div className="tw-flex tw-flex-col tw-gap-3">
              <Accordion.Root collapsible type="single" className="">
                <Accordion.Item
                  value="item1"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      アヤナリワードポイントを使用して予約を作成するにはどうすればいいですか？
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                    <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                      この方法を選択すると、予約に必要なポイント数を補うためにポイントが使用されます。予約の支払いに引き換えるポイント数を決定し、残りの金額を他の利用可能な支払い方法で支払うことができます。
                    </p>
                  </Accordion.Content>
                </Accordion.Item>

                <Accordion.Item
                  value="item2"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      予約前にこれに気づく前に部屋を予約した後、残りのポイントを使用して部屋料金を支払うことはできますか？
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    残念ながら、すでに部屋を予約したメンバーは、ポイントで支払い機能を利用できません。ポイントで支払いは、当社のウェブサイトを通じて「アヤナリワードポイントの対象」としてマークされている料金でのみ使用できます。
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 3: Membership Card */}
                <Accordion.Item
                  value="item3"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      ポイントで支払いを使用するには何ポイント必要ですか？
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    ポイントで支払いに必要な最低ポイントは500リワードポイントです。
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 4: Membership Benefits */}
                <Accordion.Item
                  value="item4"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      1ポイントあたりのポイントで支払いの価値は何ですか？
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    ポイントで支払いの価値は、参加ブランドによって異なる場合があります。ポイントで支払いを利用する際、現地通貨で価値を確認できます。
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 5: Sharing Membership */}
                <Accordion.Item
                  value="item5"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      ポイントで支払った場合、予約をキャンセルできますか？
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    予約のキャンセルポリシーは、選択した料金のキャンセルポリシーに従います。選択した料金が返金可能な場合、ポイントで支払われた金額はポイントとして返金され、別の支払い方法で支払われた部分は支払い方法に従って返金されます。
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 6: Point Transfers */}
                <Accordion.Item
                  value="item6"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      レストランやスパの料金を支払うためにポイントを使用できますか？
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    現在、レストランやスパの料金にはポイントで支払いを受け付けていません。
                  </Accordion.Content>
                </Accordion.Item>

                {/* Item 7: Points Expiration */}
                <Accordion.Item
                  value="item7"
                  className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                  <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                    <span>
                      ポイントを全て使って部屋を予約しても、ポイントを獲得できますか？
                    </span>
                    <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M12 4.5v15m7.5-7.5h-15"
                        />
                      </svg>
                    </span>
                    <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="tw-h-4 tw-w-4">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5 12h14"
                        />
                      </svg>
                    </span>
                  </Accordion.Trigger>
                  <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                    はい、アヤナリワードポイントを獲得できます。
                  </Accordion.Content>
                </Accordion.Item>
              </Accordion.Root>
            </div>
          </div>
        )}
        {activeTab === "item4" && (
          <div>
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    引き換えバウチャーの受領者を他の人に譲渡したいのですが、それは可能ですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                    はい、ご利用の製品またはサービスの受領者を他の人に譲渡することは可能です。ただし、会員は事前にアヤナリワードメンバーシップサービスに個別に通知しなければなりません。
                  </p>
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 3: Membership Card */}
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    引き換えバウチャーを交換したばかりですが、今はそれを払戻したいと思います。可能ですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  バウチャーが一度引き換えられると、キャンセル不可で払戻不可です。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 4: Membership Benefits */}
              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    引き換えバウチャーを印刷するためのプリンターがありません。他の方法はありますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  印刷された電子バウチャーに加えて、「引き換え確認」Eメールを当社のシステムから受け取るか、会員サービスからの確認Eメール/手紙も提示できます（該当する場合）。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 5: Sharing Membership */}
              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    無料宿泊交換で滞在する際、会員レベルの特典を受ける資格はありますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  はい、適用されるすべての会員特典を受ける資格があります。ただし、製品/サービスを他の人に譲渡した場合、受領者は非会員と見なされ、会員レベルの特典を受け取ることはありません。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 6: Point Transfers */}
              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>滞在に追加料金はありますか？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  ホテル毎に、無料宿泊交換バウチャーには、ハイシーズンまたはピークシーズン期間に滞在する場合、追加の人数料金および付加料金が含まれない場合があります。
                </Accordion.Content>
              </Accordion.Item>

              {/* Item 7: Points Expiration */}
              <Accordion.Item
                value="item7"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    確認済みの無料宿泊交換をキャンセルまたは変更できますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  はい、各施設のキャンセル規定に従えば、無料宿泊の部屋予約を変更またはキャンセルすることができます。ただし、変更した滞在がバウチャーの有効期限前の期間内に収まることを確認してください。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item8"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>交換バウチャーの有効期限とはどういう意味ですか？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  有効期限は、会員がバウチャーを使用できる最終日を示します。部屋の予約では、会員は有効期限前にチェックアウトしなければなりません。サービスのためには、会員は有効期限前にバウチャーを利用しなければなりません。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item9"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>交換バウチャーを受け取れない理由は何ですか？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  引き換えバウチャーを受け取れない場合はご連絡ください。メーリングリストを退会したために発生する可能性があります。
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
        {activeTab === "item3" && (
          <div>
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    アヤナリワードゴールドまたはプラチナ会員として、無料のワンランク部屋アップグレードは保証されていますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                    残念ながら、無料のワンランク部屋アップグレードは事前には保証できません。参加ブランドに到着した際の空き状況と除外日に応じて適用されます。
                  </p>
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item2"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>どのタイプの部屋アップグレードを受けますか？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  無料のワンランク部屋アップグレードは、最初に予約した同じ部屋カテゴリ内でのみ適用されます。これは、ルームからスイート、複数ベッドルームへのアップグレード（例：１ベッドルームから2ベッドルーム）、ヴィラ、およびレジデンスには含まれません。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    アーリーチェックインとレイトチェックアウトがあることに気づきました。これは保証されていますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  アヤナリワードゴールドおよびプラチナム会員用のアーリーチェックインおよびレイトチェックアウトは、到着時の空き状況に準じます。残念ながら、到着前には保証できません。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    アヤナリワードプラチナム会員として、無料の朝食を受ける資格があることに気づきましたが、すでに朝食が含まれた部屋を予約しました。この無料特典を他の人に与えることはできますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  朝食付きの部屋をすでに予約済みのメンバーは、自動的に追加の無料朝食特典を受けられません。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    第三者のサイト（直接予約ではない）から予約したアヤナリワードプラチナ会員です。無料の朝食を受ける資格はありますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  アヤナリワードプラチナ会員のための無料朝食は、会員が適用予約方法を通じて予約した場合にのみ利用可能です。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>どの予約方法が直接予約として分類されますか？</span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  直接予約とは、公式アヤナウェブサイト（www.ayana.com）、アヤナリワードウェブサイト（https://rewards.ayana.com/）、またはホテルの予約課を通じて行われた部屋の予約を指します。
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
        {activeTab === "item2" && (
          <div>
            <Accordion.Root collapsible type="single" className="">
              <Accordion.Item
                value="item1"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    ホテル滞在でポイントを獲得するにはどうすればよいですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-px-2">
                  <p className="tw-text-sm tw-font-opensans tw-flex tw-flex-col tw-gap-1">
                    適用料金・チャネルで部屋を予約することで、ホテル滞在のポイントを獲得できます。また、ポイントの対象となるいかなる付帯料金も部屋に計上されればポイントが獲得されます。
                  </p>
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item2"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    レストランやスパの料金などの付帯料金を、現金やカードなどの他の支払い方法で支払った場合、どうなりますか？ポイントは獲得できますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  はい、他の支払い方法を使用しても付帯料金でポイントを獲得できます。会計時にスタッフにポイントを獲得したいことを伝えてください。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item3"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    ホテルに滞在していません。レストランやスパの料金でポイントを獲得できますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  はい、対象ホテルに滞在していなくてもレストランやスパの料金からポイントを獲得できます。会計時にスタッフにお知らせください、ポイント獲得のお手伝いをいたします。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item4"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    適用外の部屋料金または予約方法でホテルに滞在していますが、ポイントを獲得できますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  適用外の部屋料金でポイントを獲得する資格がありません。ただし、滞在中に発生した適用追加料金からポイントを獲得することは可能です。これらの料金がお部屋付けとして計上されていることを確認してください。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item5"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    ホテルで部屋を予約し支払っていますが、実際にはホテルに宿泊していません。ポイントを獲得できますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  ポイントを獲得するには、物理的にホテルに滞在する必要があります。部屋の予約者としてのみ行動し、ホテルに宿泊していない場合、ポイントを獲得する資格はありません。
                </Accordion.Content>
              </Accordion.Item>

              <Accordion.Item
                value="item6"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    すでに参加ブランドで部屋を予約していますが、なぜ私のポイントはまだ会員アカウントにクレジットされていないのですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  ポイントは、チェックアウト日から7営業日以内に会員のアカウントにクレジットされます。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item7"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    獲得したポイントをどのように計算すればよいですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  ポイントは、参加ブランドでの支出IDR10,000
                  IDR毎に1ポイントとして会員のアカウントにクレジットされますが、デロニクスホテル
                  カラワン、デロニクスレジデンス カラワン、およびリバーサイド
                  ゴルフクラブでは、IDR15,000
                  毎に1ポイントとして加算されます。税金、サービス料、チップはポイント加算の計算に含まれません。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item8"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    予約後にアヤナリワードにサインアップしました。メンバーシップをリンクして、ポイントをクレジットできますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  会員がアヤナリワードの会員アカウントに関連付けられた同じメールアドレスで部屋を予約した場合、予約は予約日から72時間以内にアヤナリワードアカウントにリンクされます。ポイントはチェックアウト日から最大7営業日以内に会員のアカウントにクレジットされます。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item9"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    過去の滞在/取引がまだ請求されていない場合、ポイントを獲得することはできますか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  過去の滞在/取引のポイントは、チェックアウト日および取引日から60日以内に請求できます。この期間外に行われた請求は処理できません。
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item10"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    アヤナリワードアカウントでポイントを獲得できる部屋数はどれぐらいですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  <span>
                    以下の条件が満たされれば、同じ滞在期間の最大5部屋の予約に対してポイントを獲得できます：
                  </span>
                  <ul>
                    <li>部屋はあなたの名前で予約されている。</li>
                    <li>
                      あなたは、複数の部屋が予約されたホテルに実際に宿泊している。
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
              <Accordion.Item
                value="item11"
                className="tw-px-5 tw-py-2.5 tw-border-b tw-border-[#dcdbda] tw-border-solid tw-border-t-0 tw-border-x-0">
                <Accordion.Trigger className="tw-text-base tw-flex tw-items-center tw-justify-between tw-font-harriet tw-font-medium tw-bg-white tw-text-left tw-text-[#464646] tw-group tw-w-full tw-gap-4">
                  <span>
                    前回の滞在でナイトやポイントを獲得できなかった理由は何ですか？
                  </span>
                  <span className="group-data-[state=open]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M12 4.5v15m7.5-7.5h-15"
                      />
                    </svg>
                  </span>
                  <span className="group-data-[state=closed]:tw-hidden tw-shrink-0">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="tw-h-4 tw-w-4">
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M5 12h14"
                      />
                    </svg>
                  </span>
                </Accordion.Trigger>
                <Accordion.Content className="tw-my-4 tw-pr-8 tw-text-sm tw-font-opensans tw-px-2">
                  <span>
                    以下のようなポイント獲得資格のない料金がいくつかあります：
                  </span>
                  <ul>
                    <li>第三者のオンラインまたはオフライン旅行代理店の料金</li>
                    <li>イベント、会議、会議、または結婚式のグループ料金</li>
                    <li>無料宿泊</li>
                    <li>アヤナリワードバウチャーで交換された無料宿泊の特典</li>
                    <li>
                      卸売業者料金、旅行会社料金、航空会社乗組員料金、航空会社スタッフ料金、マスターアカウントに請求された部屋料金、および手数料対象料金
                    </li>
                    <li>
                      上記に記載されていない他の特別料金（家族および友人料金など）
                    </li>
                  </ul>
                </Accordion.Content>
              </Accordion.Item>
            </Accordion.Root>
          </div>
        )}
      </div>
    </div>
  );
};
